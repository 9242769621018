import React from "react"
import styled from "styled-components"

import AdminPic from "../../images/product/LAPTOP_MOCKUP_TRANSPARENT.png"
import Patient from "../../images/product/Screen Shot 2020-09-03 at 9.16.55 am.png"
import MapPic from "../../images/product/Fullscreen Background.png"
import Care from "../../images/product/LAPTOP_MOCKUP_TRANSPARENT.png"
import Hipaa from "../../images/product/logo_hipaa.png"
import { Section, Container } from "../global"

const Features = () => (
  <StyledSection id="providers">
    <StyledContainer>
      <Subtitle>Welcome to virtual care that meets your needs. See how we’re building a better experience for people just like you.</Subtitle>
      <SectionTitle>Providing high-quality online speech therapy should be simple.</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Streamlined clinical care</FeatureTitle>
          <ImgTag src={Care} alt="Girl in a jacket"></ImgTag>
          <FeatureText>
          <ul>
              <li>
              Streamlined session planning
              </li>
              <li>
              Built-in soap notes
              </li>
              <li>
              Built-in data and performance tracking tools   
              </li>
            </ul>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Bring active engagement to your sessions</FeatureTitle>
          <ImgTag src={Patient} alt="Girl in a jacket"></ImgTag>
          <FeatureText>
          <ul>
              <li>
              Immersive 3D activities
              </li>
              <li>
              Multiplayer collaborative activities
              </li>
              <li>
              Built-in rewards and motivation
              </li>
              <li>
              Watch youtube videos together 
              </li>
            </ul>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Continue Care Outside of Therapy</FeatureTitle>
          <ImgTag src={MapPic} alt="Girl in a jacket"></ImgTag>
          <FeatureText>
            <ul>
              <li>
              Client portal
              </li>
              <li>
              Assign structured homework
              </li>
              <li>
              Built-in client management tool
              </li>
              <li>
              Personalized journey map
              </li>
            </ul>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Be at ease with HIPAA Complaince and Built In Security</FeatureTitle>
          <ImgTag src={Hipaa} alt="Girl in a jacket"></ImgTag>
          <FeatureText>
          <ul>
              <li>
              Logging and Logs Integrity
              </li>
              <li>
              Data Encryption in Transit and at Rest
              </li>
              <li>
              Fince Grain Access - Enforcing Least Priviledge
              </li>
              <li>
              Data Backup, High Availability and Disaster Recovery
              </li>
            </ul>
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </StyledSection>
)

export default Features

const StyledSection = styled(Section)`
background-color: ${props => props.theme.color.white.dark};
// clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
`

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`
const ImgTag = styled.img`
  // height: 30%;
  // margin-left: 30%;
  width: 90%;
  border-radius: 10px;
  // object-fit: cover;
  // clip-path: polygon(13% 0, 100% 0, 100% 100%, 0% 100%);
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.div`
  text-align: left;
  p {
    text-align: left; 
  }    
  ul li:before {
    content: '\\2611\\0020';
  }

  ul {
    list-style: none;
    margin: 16px 0;
    // padding: 0;
    // color: ${props => props.theme.color.black.footer};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`